export const loginText = {
  heading: "Welcome To Saudi TOV Tool",
  consent1:
    "Lilly is committed to respect the Disclosure and transparency initiative guide (the guide) which requires transparency in relation to financial relationships between medical firms and health care specialists Provided by a pharmaceutical company to healthcare professionals (HCPs).",
  consent2:
    "To meet these requirements Lilly must disclose the financial support Lilly provides to you, to Saudi Food and Drug Authority (SFDA) while also adhering to applicable privacy regulations in Kingdom of Saudi Arabia. By filling this form, you give your consent to Lilly to use your personal information including National Id, Professional ID, Email, Mobile for financial disclosure reporting and in accordance with the conditions provided in the notice. For more information about Lilly’s privacy practice, please view the Privacy Statement at ",
  consent3: "I may stop participating at any time by ",
};

export const columnHeaders = {
  dashboardHeader: [
    "HCP First Name",
    "HCP Last Name",
    "Specialization",
    "Professional ID",
    "National ID",
    "Email",
    "Meeting ID",
    "Meeting Date",
  ],
  viewHCPAdminHeader: ["HCP Name", "National ID", "Professional ID", "Customer ID"],
  viewHCPMOHeader: ["HCP Name", "Customer ID","TOV Status"],
  excelHeader: [
    "HCP Title",
    "HCP First Name",
    "HCP Last Name",
    "Specialization",
    "Professional ID",
    "National ID",
    "Email",
    "Mobile",
    "Customer ID",
    "Meeting ID",
    "Meeting Owner Name",
    "Creation Date",
    "Meeting Type",
    "Meeting Date",
  ],
  hcpAdminHeader: [
    "HCP Name",
    "National ID",
    "Professional ID",
    "Email",
    "Mobile",
    "Customer ID",
  ],
  hcpMOHeader: [
    "HCP Name",
    "Customer ID",
    "TOV Status",
  ],
};

export const hcpDashboardText = {
  heading: "Welcome to Scan QR code Page, Ms XYZ",
  guidelines:
    "Please keep the QR code in front of your device camera and click on 'Start Scan' button. A camera will open, place the QR code in focus of the camera. On successful scan, a form will open. You are expected to review/update all the details before submission.",
};

export const utils = {
  errorMessage: "Error! Please try again later.",
};
