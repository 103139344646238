import CryptoJS from "crypto-js";

const encryptData = (plaintext, key) => {
    const data = CryptoJS.AES.encrypt(
        JSON.stringify(plaintext),
        key
    ).toString();

    return data;
};
const decryptData = (encryptedtext, key) => {
    if((encryptedtext.length === 0) || (encryptedtext === undefined)){
        return "";
    }
    
    const bytes = CryptoJS.AES.decrypt(encryptedtext, key);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
    
  };
export { encryptData, decryptData }