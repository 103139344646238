import { backendConfig } from "../constants/authConfig";

export function getUserDetailsByEmail(email, accessToken) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const userdata = JSON.stringify({
    user_email: email,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: userdata,
  };

  return fetch(
    `${backendConfig.backendEndpoint}user-getuserdetailsbyemail`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function upsertUser(data, accessToken) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const userdata = JSON.stringify({
    role_name: data.role_name,
    lilly_id: data.lilly_id,
    user_firstname: data.user_firstname,
    user_lastname: data.user_lastname,
    user_email: data.user_email,
    affiliate_code: "SA",
  });
  const options = {
    method: "POST",
    headers: headers,
    body: userdata,
  };

  return fetch(`${backendConfig.backendEndpoint}user-upsertuser`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function getSecrets(accessToken) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const options = {
    method: "POST",
    headers: headers
  };

  return fetch(`${backendConfig.backendEndpoint}user-getsecrets`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
