/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Grid, Button, Divider, Snackbar } from "@mui/material";
import { useState, useEffect, useRef, useContext } from "react";
import {
  Alert,
  BoxPaper,
  DashPaper,
  Title,
} from "../components/styledComponents";
import { Footer, ResponsiveAppBar } from "../components/common";
import { SessionContext } from "../providers/provider";
import DataTable from "../components/table";
import { useNavigate } from "react-router-dom";
import { CommonSearch } from "../components/commonSearch";
import { columnHeaders } from "../data/content";
import BackdropLoader from "../components/loader";
import { getAllAttendee, getExportData } from "../services/attendeeservice";
import ExcelJS from "exceljs";
import dayjs from "dayjs";

function AdminDashBoard() {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [excelData, setExceldata] = useState({});
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const userSession = useContext(SessionContext);
  const increment = 50;
  const limit = 50;

  const getAllData = (accessToken, filters) => {
    let calls = [];
    let loopcount = Math.ceil(data.length / increment);
    for (let i = 0; i < loopcount; i++) {
      calls.push(getExportData(accessToken, filters, i * increment, limit));
    }
    let totalProgress = 0;
    calls.forEach((call) => {
      call.then((batchData) => {
        const batchDataLength = batchData[0].length;
        const progress = (batchDataLength / data.length) * 100;
        totalProgress += progress;
        setProgress(Math.round(totalProgress));
      });
    });
    return calls;
  };
  useEffect(() => {
    if (userSession.userName !== "") {
      let accessToken = localStorage.getItem("apitoken");
      async function fetchData() {
        setIsLoading(true);
        const response = await getAllAttendee(accessToken);
        setData(response[0]);
        setAllData(response[0]);
        setIsLoading(false);
      }
      fetchData();
    }
  }, [userSession]);

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  async function handleDownloadExcel() {
    let accessToken = localStorage.getItem("apitoken");
    let filters = {
      start_date: excelData?.start_date,
      end_date: excelData?.end_date,
      meeting_id: excelData?.meeting_code,
      national_id: excelData?.national_id,
      professional_id: excelData?.professional_id,
      lilly_id: null,
    };
    setIsLoading(true);
    setIsExporting(true);
    Promise.all(getAllData(accessToken, filters))
      .then(async (response) => {
        let allData = [];
        for (let i = 0; i < response.length; i++) {
          let item = response[i];
          if (item && item.message === "Successful" && item[0].length > 0) {
            allData = [...allData, ...item[0]];
          }
        }
        const workbook = new ExcelJS.Workbook();
        const adminWorksheet = workbook.addWorksheet("allData");
        adminWorksheet.getRow(1).values = columnHeaders.excelHeader;
        adminWorksheet.getRow(1).height = 30;
        adminWorksheet.getRow(1).font = {
          family: 4,
          size: 12,
          bold: true,
        };
        adminWorksheet.getRow(1).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        let headerarr = [
          { key: "specialist_title", width: 40 },
          { key: "hcp_firstname", width: 40 },
          { key: "hcp_lastname", width: 40 },
          { key: "hcp_specialization", width: 40 },
          { key: "professional_id", width: 40 },
          { key: "national_id", width: 40 },
          { key: "email_id", width: 40 },
          { key: "hcp_mobile", width: 40 },
          { key: "customer_id", width: 40 },
          { key: "meeting_id", width: 40 },
          { key: "created_by", width: 40 },
          { key: "created_on", width: 40 },
          { key: "meeting_type_name", width: 40 },
          { key: "meeting_date", width: 40 },
        ];
        adminWorksheet.columns = headerarr;
        allData.forEach((x, i) => {
          const rowNumber = i + 1;
          x.created_on = new Date(x.created_on).toLocaleDateString("en-GB");
          x.meeting_date = new Date(x.meeting_date).toLocaleDateString("en-GB");
          x.hcp_mobile = x.hcp_mobile !== "-1" ? x.hcp_mobile : "NA";
          x.customer_id = x.customer_id ? x.customer_id : "NA";
          let row = adminWorksheet.addRow(x);
          row.alignment = {
              horizontal: "left",
              vertical: "middle",
              wrapText: true
          };
          row.height = 30; 
        });

        const blobdata = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(new Blob([blobdata]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Admin_Export.xlsx`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
        setProgress(0);
        handleAlertClick("Downloaded Successfully", "success");
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setIsExporting(false);
        handleAlertClick("Issue in downloading excel", "error");
      });
  }

  let callback = (valueFromSearch, filterData) => {
    setData(valueFromSearch);
    setExceldata(filterData);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>

      <ResponsiveAppBar />
      <Grid container>
        <BoxPaper>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <DashPaper sx={{ pb: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ p: 3, pt: 1 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12} textAlign={"center"}>
                    <Title sx={{ color: "#1976d2", fontWeight: "bold" }}>
                      Dashboard
                    </Title>
                  </Grid>
                  <CommonSearch
                    data={data}
                    allData={allData}
                    callbackFunc={callback}
                  />
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    sx={{ pr: 5, py: 2 }}
                  >
                    <Button
                      sx={{ fontWeight: "bold" }}
                      variant="contained"
                      onClick={handleDownloadExcel}
                    >
                      Export to Excel
                    </Button>
                  </Grid>
                  <Grid item>
                    {isLoading ? (
                      <BackdropLoader
                        show={isLoading}
                        isExporting={isExporting}
                        value={progress}
                      />
                    ) : (
                      <>
                        <Box
                          sx={{
                            flexGrow: 1,
                            justifyContent: "center",
                            display: { xs: "flex", sm: "flex", md: "none" },
                          }}
                        >
                          <DataTable
                            rows={data}
                            tableHeader={columnHeaders.dashboardHeader}
                          />
                        </Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            justifyContent: "center",
                            display: { xs: "none", sm: "none", md: "flex" },
                          }}
                        >
                          <DataTable
                            rows={data}
                            tableHeader={columnHeaders.dashboardHeader}
                          />
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </DashPaper>
            </Grid>
          </Grid>
        </BoxPaper>
      </Grid>
      <Footer />
    </>
  );
}

export default AdminDashBoard;
