import { backendConfig } from '../constants/authConfig';

export async function getMsalToCustomToken(emailID, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", bearer);
    const data = JSON.stringify({
        'emailID': emailID
    });
    const options = {
        method: "POST",
        headers: headers,
        body: data
    };

    return fetch(backendConfig.msalGatewayEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function getPassportToCustomToken(emailID, accessToken) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const data = JSON.stringify({
        'emailID': emailID
    });
    const options = {
        method: "POST",
        headers: headers,
        body: data
    }
    return fetch(backendConfig.passportGatewayEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function getRefreshToken(accessToken, emailID) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const data = JSON.stringify({
        'emailID': emailID
    });
    const options = {
        method: "POST",
        headers: headers,
        body: data
    }
    return fetch(backendConfig.refreshGatewayEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}