import { forwardRef } from "react";
import {
  Chip,
  Paper,
  Typography,
  TextField,
  Box,
  TableCell,
  TableHead,
  Alert as MuiAlert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import bgimg from "../images/blue_background.png";

export const DashPaper = styled(Paper)({
  margin: "1% 12%",
  background: "#FFFFFF",
  borderRadius: "8px",
});

export const Container = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flexStart",
  padding: 0,
  margin: "5% 0%",
  background: "#FFFFFF",
});

export const BoxPaper = styled(Box)({
  background: `url(${bgimg})`,
  position: "absolute",
  width: "100%",
  backgroundSize: "contain",
  backgroundRepeat: "repeat-x",
  paddingTop: "32px",
  paddingBottom: "64px",
});

export const Title = styled(Typography)`
  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: "center";
`;

export const Label = styled(Typography)`
  margin: 0% 0% 2% 0%;
  color: #333232;
  float: left;
  line-height: 2.75;
  font-size: 14px;
  padding-right: 1%;
  margin-bottom: 1%;
  font-weight: 500;
`;

export const Input = styled(TextField)({
  maxHeight: "32px",
  width: "92%",
});

export const Asterisk = styled("label")({
  color: "red",
});

export const StyledTableCell = styled(TableCell)({
  color: "white",
  textAlign: "center",
  fontSize: "12px",
  width: "30%",
  padding: "8px 4px",
});

export const StyledTableErrorCell = styled(TableCell)({
  textAlign: "center",
  fontSize: "12px",
  padding: "8px 4px",
});

export const StyledtableHead = styled(TableHead)({
  backgroundColor: "#1976d2",
  textAlign: "center",
  fontSize: "12px",
});

export const StyledTableCellRow = styled(TableCell)({
  width: "70%",
  padding: "8px 4px",
});

export const ChipStyled = styled(Chip)({
  padding: "8px",
  height: "24px",
  background: "#EDECEA",
  borderRadius: "4px",
  "& .MuiChip-deleteIcon": {
    display: "none",
  },
  "&:hover": {
    "& .MuiChip-deleteIcon": {
      display: "block",
    },
  },
  "&.Mui-disabled": {
    WebkitTextFillColor: "#000000",
    color: "#000000",
  },
});

export const Alert = forwardRef((props, ref) => (
  <MuiAlert
    elevation={10}
    ref={ref}
    variant="filled"
    sx={{ width: "100%" }}
    {...props}
  />
));

export const Placeholder = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "inherit",
  color: "#A4A2A2",
});
