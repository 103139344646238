import { backendConfig } from '../constants/authConfig';

export async function getAllMeetingsType(accessToken) {
    const headers = new Headers();
    // const bearer = `Bearer ${accessToken}`;
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const options = {
        method: "POST",
        headers: headers,
    };

    return fetch(`${backendConfig.backendEndpoint}meeting-getallmeetingtype`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}