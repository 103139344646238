import { Typography, Box } from "@mui/material";
import { useState } from "react";

function PageNotFound() {
  const isLoading = useState("Page Not Found");

  return (
    <>
      {
        <Box>         
            <Typography variant="h1" textAlign="center" color="#1976d2">
              404
            </Typography>      
          <Typography variant="h4" textAlign="center">
            {isLoading}
          </Typography>
        </Box>
      }
    </>
  );
}

export default PageNotFound;