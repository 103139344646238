import { backendConfig } from '../constants/authConfig';

export async function getAllMeetings(accessToken) {
    const headers = new Headers();
    // const bearer = `Bearer ${accessToken}`;
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const options = {
        method: "POST",
        headers: headers,
    };

    return fetch(`${backendConfig.backendEndpoint}meeting-getallmeeting`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getMeetingById(data, accessToken) {
    const headers = new Headers();
    // const bearer = `Bearer ${accessToken}`;
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const meetingData = JSON.stringify({
        "meeting_id": data.meeting_id
    });
    const options = {
        method: "POST",
        headers: headers,
        body: meetingData
    };

    return fetch(`${backendConfig.backendEndpoint}meeting-getmeetingbyid`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getMeetingCreatedById(data, accessToken){
    const headers = new Headers();
    // const bearer = `Bearer ${accessToken}`;
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const meetingData = JSON.stringify({
        "created_by": data.created_by
    });
    const options = {
        method: "POST",
        headers: headers,
        body: meetingData
    };

    return fetch(`${backendConfig.backendEndpoint}meeting-getmeetingcreatedbyid`, options)
        .then(response => response.json())
        .catch(error => console.log(error));  
}

export async function addMeeting(data, accessToken) {
    const headers = new Headers();
    // const bearer = `Bearer ${accessToken}`;
    // data.secondary_owner format
    // [{"user_email":"paras.swarnkar@network.lilly.com","lilly_id":"L057479","user_firstname":"Paras","user_lastname":"Swarnkar"},
    // {"user_email":"dhaga_shreya@network.lilly.com","lilly_id":"L005219","user_firstname":"Shreya","user_lastname":"Dhaga"}]
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const meetingData = JSON.stringify({
        "meeting_type_id": data.meeting_type_id,
        "meeting_id": data.meeting_id,
        "meeting_date": data.meeting_date,
        "created_by": data.created_by,
        "secondary_owner": data.secondary_owner
    });
    const options = {
        method: "POST",
        headers: headers,
        body: meetingData
    };

    return fetch(`${backendConfig.backendEndpoint}meeting-addmeeting`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function updateMeeting(data, accessToken) {
    const headers = new Headers();
    // const bearer = `Bearer ${accessToken}`;
    headers.append("Content-Type", "application/json");
    headers.append("authorizationtoken", accessToken);
    const meetingData = JSON.stringify({
        "meeting_id":data.meeting_id,
        "meeting_type": data.meeting_type,
        "meeting_date": data.meeting_date,
        "updated_by": data.updated_by
    });
    const options = {
        method: "POST",
        headers: headers,
        body: meetingData
    };

    return fetch(`${backendConfig.backendEndpoint}meeting-updatemeeting`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}