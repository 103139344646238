/* eslint-disable react/jsx-no-undef */
import { Box, Grid, Button, Snackbar, Typography, Link } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import QRCode from "qrcode";
import {
  Alert,
  BoxPaper,
  DashPaper,
  Input,
  Title,
} from "../components/styledComponents";
import {
  Footer,
  ResponsiveAppBar,
  SelectDate,
  SingleSelect,
  ChipSelect,
  CopyLink,
} from "../components/common";
import { Asterisk } from "../components/styledComponents";
import { addMeeting } from "../services/meetingservice";
import { SessionContext } from "../providers/provider";
import { getAllMeetingsType } from "../services/meetingtype";
import { callSecondaryOwners } from "../constants/graph";
import BackdropLoader from "../components/loader";

function GenerateQRPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [qr, setQr] = useState("");
  const userSession = useContext(SessionContext);
  const [meetingData, setMeetingData] = useState({
    MeetingId: "",
    MeetingType: 0,
    MeetingDate: null,
    SecondaryOwner: [],
  });
  // const [error, setError] = useState("");
  const [meetingOptions, setMeetingOptions] = useState([]);
  const [secondaryOwners, setSecondaryOwners] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const [meetingIDHT, setMeetingIDHT] = useState("");
  const [meetingTypeHT, setMeetingTypeHT] = useState("");
  const [meetingDateHT, setMeetingDateHT] = useState("");
  // const [secondaryHT, setSecondaryHT] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });

  useEffect(() => {
    if (userSession.userName !== "") {
      let accessToken = localStorage.getItem("apitoken");
      async function fetchData() {
        setIsLoading(true);
        const response = await getAllMeetingsType(accessToken);
        setMeetingOptions(
          response.result?.reduce(
            (a, { meeting_type_id, meeting_type_name }) => ({
              ...a,
              [meeting_type_id]: meeting_type_name,
            }),
            {}
          )
        );
        setIsLoading(false);
      }
      fetchData();
    }
  }, [userSession]);

  useEffect(() => {
    if (userSession.userName !== "") {
      let accessToken = localStorage.getItem("msalToken");
      async function fetchData() {
        const response = await callSecondaryOwners(accessToken);

        let filteredowner = response.filter(
          (b) => b.onPremisesSamAccountName !== userSession.userId
        );
        let sorted_data = filteredowner.sort(function (a, b) {
          return a.displayName < b.displayName ? -1 : 1;
        });

        setSecondaryOwners(
          sorted_data?.reduce(
            (a, { id, displayName }) => ({
              ...a,
              [id]: displayName,
            }),
            {}
          )
        );
        setFilterValue(response);
      }
      if (secondaryOwners.length === 0) {
        fetchData();
      }
    }
  }, [userSession, secondaryOwners]);

  const validateMeetingData = (data) => {
    let validate = false;
    if (
      data.meeting_id === "" ||
      data.meeting_type_id === 0 ||
      data.meeting_date === null ||
      data.meeting_date === 0
    ) {
      data.meeting_id === ""
        ? setMeetingIDHT("This field is mandatory")
        : setMeetingIDHT("");
      data.meeting_type_id === 0
        ? setMeetingTypeHT("This field is mandatory")
        : setMeetingTypeHT("");
      data.meeting_date === undefined || data.meeting_date === null
        ? setMeetingDateHT("This field is mandatory")
        : setMeetingDateHT("");
    } else if (
      data.meeting_id.includes("/") ||
      data.meeting_id.includes(`\\`)
    ) {
      setMeetingIDHT("Meeting ID cannot include /");
      validate = true;
    } else {
      setMeetingIDHT("");
      setMeetingTypeHT("");
      setMeetingDateHT("");
      validate = true;
    }
    return validate;
  };

  const GenerateQRCode = () => {
    //QR Generate Function
    // const value = JSON.stringify(meetingData);
    const apiToken = localStorage.getItem("apitoken");

    const selectedSO = filterValue.filter((e) =>
      meetingData.SecondaryOwner?.some((so) => so === e.id)
    );
    const uniqueSO = [];
    const map = new Map();
    for (const item of selectedSO) {
      if (!map.has(item.id)) {
        map.set(item.id, true); // set any value to Map
        uniqueSO.push({
          user_email: item.mail,
          lilly_id: item.onPremisesSamAccountName,
          user_firstname: item.givenName,
          user_lastname: item.surname,
        });
      }
    }

    let data = {
      meeting_id: meetingData.MeetingId,
      meeting_type_id: parseInt(meetingData.MeetingType, 10),
      meeting_date: meetingData.MeetingDate,
      secondary_owner: uniqueSO,
      created_by: userSession.userId,
    };
    const isValidated = validateMeetingData(data);

    isValidated &&
      addMeeting(data, apiToken).then((response) => {
        const { message, statusCode } = response;
        if (message === "Successful") {
          QRCode.toDataURL(
            `${window.location.origin}/submit/hcpData/${meetingData.MeetingId}`,
            {
              width: 300,
              margin: 2,
            },
            (err, text) => {
              if (err) return console.error(err);
              setQr(text);
            }
          );
          handleAlertClick("QR code generated successfully.", "success");
        } else if (statusCode === 500) {
          handleAlertClick("QR code for this meeting already exist", "error");
          // setQr("");
          QRCode.toDataURL(
            `${window.location.origin}/submit/hcpData/${meetingData.MeetingId}`,
            {
              width: 300,
              margin: 2,
            },
            (err, text) => {
              if (err) return console.error(err);
              setQr(text);
            }
          );
        }
      });
  };

  const handleSelectChange = (name, value) => {
    setMeetingData((prevState) => {
      const field = prevState;
      field[name] = value;
      return { ...field };
    });
  };

  const handleDelete = (name, value) => {
    setMeetingData((prevState) => {
      const field = prevState;
      const index = field[name].indexOf(value);
      field[name].splice(index, 1);
      return { ...field };
    });
  };

  const handleMenuItems = (name) => {
    let isDisabled = false;
    if (meetingData?.SecondaryOwner.length === 20) {
      isDisabled = true;
    } else {
    }
    return isDisabled;
  };

  // const handleChange = (event) => {
  //   setSecondaryHT(event.target.value);
  //   if (
  //     meetingData?.SecondaryOwner.length !== 0 &&
  //     meetingData?.SecondaryOwner.length < 2
  //   ) {
  //     setSecondaryHT("length must be greater than 5");
  //   } else {
  //     setSecondaryHT("");
  //   }
  // };

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-_@]+$/;

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={10000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      <ResponsiveAppBar />
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <>
          <BoxPaper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <DashPaper sx={{ py: 1.5,pr:4 }}>
                  <Grid
                    container
                    spacing={3}
                    // sx={{ p: 1.5, pt: 0 }}
                    // justifyContent="center"
                    alignItems="center"
                  >
                    
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Title color={"#1976d2"} sx={{ fontWeight: "bold" }}>
                        Generate Meeting QR Code
                      </Title>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">
                        Meeting ID<Asterisk>*</Asterisk>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      md={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Input
                        id="outlined-basic"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={meetingData.MeetingId}
                        placeholder="Enter Meeting ID"
                        onKeyDown={(event) => {
                          if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setMeetingData({
                            ...meetingData,
                            MeetingId: e.target.value,
                          });
                        }}
                        error={meetingIDHT !== ""}
                        helperText={meetingIDHT}
                        sx={{
                          "& .Mui-error": {
                            color: "black",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">
                        Meeting Type<Asterisk>*</Asterisk>
                      </Typography>
                    </Grid>
                    {meetingTypeHT ? (
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={4}
                        display="flex"
                        justifyContent="center"
                        sx={{ mt: 2 }}
                      >
                        <SingleSelect
                          fieldName="MeetingType"
                          selected={meetingData?.MeetingType}
                          dropdownData={meetingOptions}
                          getSelectedValue={handleSelectChange}
                          placeholder="Select Meeting Type"
                          error={meetingTypeHT !== ""}
                          helpertext={meetingTypeHT}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={4}
                        sx={{ mt: 1 }}
                        display="flex"
                        justifyContent="center"
                      >
                        <SingleSelect
                          fieldName="MeetingType"
                          selected={meetingData?.MeetingType}
                          dropdownData={meetingOptions}
                          getSelectedValue={handleSelectChange}
                          placeholder="Select Meeting Type"
                          error={meetingTypeHT !== ""}
                          helpertext={meetingTypeHT}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">
                        Meeting Date<Asterisk>*</Asterisk>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} justifyContent="center">
                      <SelectDate
                        fieldName="MeetingDate"
                        getDate={handleSelectChange}
                        dateSelected={meetingData.MeetingDate}
                        error={meetingDateHT !== ""}
                        helpertext={meetingDateHT}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">
                        Secondary Owner
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      md={4}
                      style={{ paddingLeft: "40px", marginTop: "8px" }}
                    >
                      <ChipSelect
                        fieldName="SecondaryOwner"
                        selected={
                          typeof meetingData?.SecondaryOwner === "string"
                            ? meetingData?.SecondaryOwner.split(",")
                            : meetingData?.SecondaryOwner
                        }
                        dropdownData={secondaryOwners}
                        getSelectedValue={handleSelectChange}
                        setDeletedValue={handleDelete}
                        placeholder="Select Secondary Owners"
                        handleMenuItems={handleMenuItems}
                      />
                    </Grid>
                  </Grid>
                  <Box textAlign="center" mt={3}>
                    <Button
                      sx={{ fontWeight: "bold" }}
                      variant="contained"
                      onClick={GenerateQRCode}
                    >
                      Generate QR Code
                    </Button>
                  </Box>

                  <Box textAlign="center" mt={3}>
                    {qr && (
                      <>
                        <img
                          sx={{ justifyContent: "center" }}
                          src={qr}
                          alt="QRCode"
                        />
                        <Typography sx={{ alignItems: 'center' }}>Please find the QR code link below:</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
                          {/* Link for the generated QR code */}
                          <Link sx={{ marginRight: 1 }}>
                            {`${window.location.origin}/submit/hcpData/${meetingData.MeetingId}`}
                          </Link>
                          <CopyLink link={`${window.location.origin}/submit/hcpData/${meetingData.MeetingId}`} />
                        </Box>
                        <Box my={2}>
                          <Button
                            sx={{ fontWeight: "bold" }}
                            variant="contained"
                            href={qr}
                            download={`${meetingData.MeetingId}_${meetingData.MeetingDate}`}
                          >
                            Download
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </DashPaper>
              </Grid>
            </Grid>
          </BoxPaper>
          <Footer />
        </>
      )}
    </>
  );
}
export default GenerateQRPage;
