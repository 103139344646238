import * as React from "react";
import { Backdrop } from "@mui/material";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/joy/CircularProgress";

const BackdropLoader = (props) => {
  const { show } = props;

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: 1500,
          backgroundColor: "white"
        }}
        open={show}
      >
        <CircularProgress
          key="indeterminate"
          color="primary"
          variant="solid"
          determinate={props.isExporting}
          value={props.value}
          sx={{
            "--CircularProgress-size": "85px",
          }}
        >
          {props.isExporting && (
            <Typography fontWeight={"bold"} sx={{color:"darkslategrey"}}>
              {props.value}%
            </Typography>
          )}
        </CircularProgress>
      </Backdrop>
    </div>
  );
};

export default BackdropLoader;
