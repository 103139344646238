/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {
  Typography,
  Grid,
  Select,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Link,
  Snackbar,
  SnackbarContent, Tooltip
} from "@mui/material";
import logo from "../images/lilly_logo.png";
import PersonIcon from "@mui/icons-material/Person";
import { SessionContext } from "../providers/provider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ChipStyled, Placeholder } from "./styledComponents";
import { msalConfig, passportRedirectURL } from "../constants/authConfig";
import format from "date-fns/format";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import BackdropLoader from "./loader";
import "dayjs/locale/en-gb";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Footer = () => {
  return (
    <React.Fragment>
      <AppBar position="fixed" sx={{ top: "auto", bottom: 0, height: "55px" }}>
        <Toolbar
          sx={{
            background: "linear-gradient(90deg, #09c6f9 0%, #045de9 100%)",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Grid container justifyContent="center" alignItems="center">
            <Typography color="#fff">
              Copyright @ 2023 Eli Lilly and Company. All rights reserved.|
              PP-MG-SA-0022
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

function ResponsiveAppBar() {
  const userSession = useContext(SessionContext);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    //clear localstorage
    localStorage.clear();
    //clear session Storage
    sessionStorage.clear();
    //clear cookies
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      document.cookie = cookies[i] + "=; expires=" + new Date(0).toUTCString();
    }
    // redirect to main page
    userSession.userRole === "HCP"
      ? window.location.replace(passportRedirectURL.logoutURL)
      : window.location.replace(msalConfig.auth.redirectUri + "/");
  };

  return (
    <AppBar position="fixed">
      <Toolbar
        sx={{
          background: "linear-gradient(90deg, #09c6f9 0%, #045de9 100%)",
          p: 0,
        }}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={4} sm={2} md={1.4}>
            {userSession.userRole === "Admin" ? (
              <Link href={"/admin/dashboard"} underline="none">
                <img
                  src={logo}
                  alt="lillyLogo"
                  style={{ height: "60px" }}
                ></img>
              </Link>
            ) : userSession.userRole === "Meeting Owner" ? (
              <Link href={"/mo/dashboard"} underline="none">
                <img
                  src={logo}
                  alt="lillyLogo"
                  style={{ height: "60px" }}
                ></img>
              </Link>
            ) : userSession.userRole === "HCP" ? (
              <Link href={"/hcp/dashboard"} underline="none">
                <img
                  src={logo}
                  alt="lillyLogo"
                  style={{ height: "60px" }}
                ></img>
              </Link>
            ) : ""}
          </Grid>
          <Grid item xs={5.5} sm={6} md={8.5}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex", md: "flex" },
              }}
            >
              <Typography variant="h5" fontWeight="700">
                Saudi Transfer Of Value
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", sm: "none", md: "none" },
              }}
            >
              <Typography variant="h5" fontWeight="700">
                Saudi ToV
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={0.5} sm={0.8} md={0.4}>
            <Tooltip>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <PersonIcon fontSize="large" sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Grid>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", sm: "none", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ p: 0, pl: 2 }}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Box>
                  <Typography variant="subtitle1" fontWeight="700">
                    {userSession.userFirstName} {userSession.userLastName}
                  </Typography>
                  <Typography variant="body1">
                    Role:{userSession.userRole}
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>
          <Grid item xs={1} sm={3} md={1.7}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex", md: "flex" },
              }}
            >
              <Box pr={2}>
                <Typography variant="subtitle1" fontWeight="700">
                  {userSession.userFirstName} {userSession.userLastName}
                </Typography>
                <Typography variant="body1">
                  Role: {userSession.userRole}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export { Footer, ResponsiveAppBar };

export const SingleSelect = (props) => {
  const {
    selected,
    fieldName,
    dropdownData,
    getSelectedValue,
    placeholder,
    helpertext,
    error,
    disabled,
  } = props;

  const handleChange = (event) => {
    const { value } = event.target;
    getSelectedValue(fieldName, value);
  };
  const menuProps = {
    "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper": { width: "150px" },
  };

  return (
    <>
      <FormControl sx={{ width: "92%" }} size="small" error={error}>
        <Select
          sx={{
            m: 0,
            pl: 0,
            "& .MuiInputBase-input.Mui-disabled": {
              cursor: "not-allowed",
            },
          }}
          key={selected}
          displayEmpty
          disabled={disabled ?? false}
          name={fieldName}
          value={selected}
          onChange={handleChange}
          MenuProps={menuProps}
          renderValue={(selected) => {
            if (
              selected?.length === 0 ||
              selected === undefined ||
              selected === null ||
              selected === 0
            ) {
              return <Placeholder>{placeholder}</Placeholder>;
            } else {
              return dropdownData[selected] ?? placeholder;
            }
          }}
        >
          {Object.keys(dropdownData).length > 0 ? (
            Object.keys(dropdownData)?.map((id) => (
              <MenuItem
                sx={{ minWidth: "97%", maxWidth: "91%" }}
                key={`menu_it_${id}`}
                value={id}
              >
                {dropdownData[id]}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Items Found</MenuItem>
          )}
        </Select>
        <FormHelperText>{helpertext}</FormHelperText>
      </FormControl>
    </>
  );
};

export const ChipSelect = (props) => {
  const userSession = useContext(SessionContext);

  const {
    fieldName,
    selected,
    disabled,
    placeholder,
    dropdownData,
    getSelectedValue,
    setDeletedValue,
    helpertext,
    error,
    handleMenuItems,
  } = props;

  const [openSelect, setSelect] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { value } = event.target;
    getSelectedValue(fieldName, value);
  };

  const handleChipDelete = (event, value) => {
    event.preventDefault();
    setDeletedValue(fieldName, value);
  };

  return (
    <>
      <FormControl sx={{ width: "95.5%" }} size="small" error={error}>
        <Select
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "lightgrey",
              },
            },
          }}
          name={fieldName}
          open={openSelect}
          onClick={() => setSelect(disabled ? false : !openSelect)}
          multiple
          displayEmpty
          onChange={handleChange}
          value={selected}
          renderValue={(selection) => {
            if (selection?.length === 0) {
              return <Placeholder>{placeholder}</Placeholder>;
            }
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selection?.map((value) => (
                  <ChipStyled
                    key={value}
                    label={dropdownData[value]}
                    // disabled={disabled ?? false}
                    onDelete={(e) => handleChipDelete(e, value)}
                  />
                ))}
              </Box>
            );
          }}
          input={<OutlinedInput id="select-multiple-chip" />}
        >
          {Object.keys(dropdownData).length > 0 ? (
            Object.keys(dropdownData).map((id) => (
              <MenuItem
                key={id}
                value={id}
                disabled={handleMenuItems(fieldName)}
                sx={{
                  "& .MuiMenuItem-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                    backgroundColor: "#EDECEA",
                  },
                }}
              >
                {dropdownData[id]}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Items Found</MenuItem>
          )}
        </Select>
      </FormControl>
      <FormHelperText>Maximum 20 secondary owners are allowed</FormHelperText>
    </>
  );
};

export default function DateFilter(start, end, data) {
  let filtered = data.filter((filterData) => {
    let dataDate = new Date(filterData.Date);
    return filtered >= start && filtered <= end;
  });
  return filtered;
}

export const SelectDate = (props) => {
  const [date, setDate] = useState(null);
  const { fieldName, getDate, dateSelected, helpertext, error, minDate } =
    props;

  useEffect(() => {
    let date1 = dateSelected
      ? format(new Date(`${dateSelected}`), "yyyy-MM-dd")
      : null;
    setDate(date1);
  }, [dateSelected]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DemoContainer
          components={["DatePicker", "DatePicker"]}
          sx={{ ml: "16px" }}
        >
          <DatePicker
            disablePast
            slotProps={{
              textField: { size: "small" },
              field: { clearable: true }
            }}
            onError={error}
            sx={{ width: "95%" }}
            value={date}
            closeOnSelect={false}
            onChange={(newValue) => {
              if (newValue && !isNaN(new Date(newValue).getTime())) {
                setDate(newValue);
                newValue = format(new Date(`${newValue}`), "yyyy-MM-dd");
                getDate(fieldName, newValue);
              } else {
                setDate(null);
              }
            }}
            inputFormat="DD-MM-YYYY"
            views={["year", "month", "day"]}
            minDate={minDate}
          />
        </DemoContainer>
        <FormHelperText sx={{ pl: 4, color: "#d32f2f" }}>
          {helpertext}
        </FormHelperText>
      </LocalizationProvider>
    </>
  );
};

export const LoadingScreen = () => {
  return (
    <div>
      <BackdropLoader show={"loading"} />
    </div>
  );
};

export const CountdownTimer = ({ initialSeconds }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    // Exit early if countdown is finished
    if (seconds <= 0) {
      localStorage.clear();
      sessionStorage.clear();
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        document.cookie =
          cookies[i] + "=; expires=" + new Date(0).toUTCString();
      }
      window.location.replace(msalConfig.auth.redirectUri + "/");
      return;
    }

    // Set up the timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds]);

  // Format the remaining time (e.g., “00:05:10” for 5 minutes and 10 seconds)
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return <>{formatTime(seconds)}</>;
};

export const CopyLink = ({ link }) => {
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setOpen(true);
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000); // Hide tooltip after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={tooltipOpen ? "Copied!" : "Copy to clipboard"}>
        <IconButton onClick={handleCopy} sx={{ color: 'blue' }}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          message="Link copied to clipboard"
          style={{ backgroundColor: 'grey', color: '#fff' }}
        />
      </Snackbar>
    </div>
  );
};

