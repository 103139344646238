import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as msal from "@azure/msal-browser";
import { MsalContext } from "./providers/provider";
import { msalConfig } from "./constants/authConfig";

const msalInstance = new msal.PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <MsalContext.Provider value={msalInstance}>
      <App />
    </MsalContext.Provider>
  // </React.StrictMode>
);

reportWebVitals();