import React, { useEffect } from "react";
import { MsalContext } from "../providers/provider";
import { loginRequest } from "../constants/authConfig";
import { passportRedirectURL } from "../constants/authConfig";
import {
  Paper,
  Grid,
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import logo from "../images/LillyLogo_RGB_Red.png";
import { useState } from "react";
import { Footer } from "../components/common";
import { Title } from "../components/styledComponents";
import { loginText } from "../data/content";
import BackdropLoader from "../components/loader";

function Login(props) {
  const [permission, setPermission] = useState(false);
  const [isHcp, setIsHcp] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const msalInstance = React.useContext(MsalContext);
  useEffect(() => {
    if ((window.location.pathname !== '/') ||
      (localStorage.getItem('redirectto') && !localStorage.getItem('redirectto').includes('submit'))) {
      localStorage.setItem('redirectto', window.location.pathname);
    }
  }, [])
  useEffect(() => {
    setShowLoader(props.token);
  }, [props.token])
  useEffect(() => {
    if (window.location.pathname !== '/') {
      if (
        localStorage.getItem('redirectto').split('/')[1] === 'hcp' ||
        localStorage.getItem('redirectto').split('/')[1] === 'submit') {
        setIsHcp(true)
      }
    }
    if (window.location.pathname === '/unauthorized') {
      setShowLoader(false);
      setIsAuthorized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('redirectto')])
  const handleSSORedirect = () => {
    msalInstance.handleRedirectPromise().then((authResult) => {
      if (authResult !== null) {
      } else {
        msalInstance.loginRedirect(loginRequest);
      }
    });
  };
  const handlePassportLogin = () => {
    window.location.assign(passportRedirectURL.loginURL);
  };
  const handlePassportRegister = () => {
    window.location.assign(passportRedirectURL.registerURL);
  };
  return (
    <>
      {showLoader ? <BackdropLoader show={showLoader} /> :
        isAuthorized ?
          (<>
            <Box
            >
              <Box textAlign="center">
                <Box
                  component="img"
                  sx={{
                    mt: 1,
                    height: 70,
                    width: 100,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Lilly Logo"
                  src={logo}
                />
                <Typography variant="h4" color="#1565c0" mx={2}>
                  {loginText.heading}
                </Typography>
              </Box>

              <Grid
                container
                spacing={1}
                pt={1}
                direction="row"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={7} mx={3}>
                  <Paper
                    sx={{
                      mt: 4,
                      mx: 1,
                      mb: 4,
                      p: 5,
                      height: "auto",
                      minHeight: "200px",
                      borderRadius: 2,
                      opacity: 0.8,
                    }}
                    elevation={3}
                  >
                    <Title variant="h6" textAlign="center">
                      HCP Login
                    </Title>
                    <p>{loginText.consent1}</p>
                    <p>
                      {loginText.consent2}
                      <a href="https://www.lillyprivacy.com/SA-en/hcp">
                        lillyprivacy.com/SA-en/hcp.
                      </a>
                    </p>
                    <p>
                      {loginText.consent3}
                      <a href="mailto:privacy@lilly.com">privacy@lilly.com</a>
                    </p>
                    <Box textAlign="center">
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            sx={{
                              color: "#A4A2A2",
                              "&.Mui-checked": { color: "#E02F2B" },
                            }}
                          />
                        }
                        onClick={(e) => {
                          setPermission(!permission);
                        }}
                        checked={permission}
                        label="Accept terms and Conditions"
                      />
                    </Box>
                    <Box mt={2} textAlign="center">
                      <Stack spacing={4} direction="row" justifyContent="center">
                        <Button
                          sx={{ backgroundColor: "#1565c", fontWeight: "bold" }}
                          variant="contained"
                          disabled={permission === false}
                          onClick={() => handlePassportLogin()}
                        >
                          HCP LOGIN
                        </Button>
                        <Button
                          sx={{ backgroundColor: "#1565c", fontWeight: "bold" }}
                          variant="contained"
                          onClick={() => handlePassportRegister()}
                        >
                          REGISTER
                        </Button>
                      </Stack>
                    </Box>
                  </Paper>
                </Grid>
                {isHcp ? '' : <Grid item xs={12} md={4} mx={3}>
                  <Paper
                    sx={{
                      mt: 4,
                      mx: 1,
                      mb: 4,
                      p: 5,
                      height: "auto",
                      minHeight: "180px",
                      borderRadius: 2,
                      opacity: 0.9,
                    }}
                    elevation={3}
                  >
                    <Box sx={{ minWidth: 200 }}>
                      <Box textAlign="center">
                        <Title variant="h6" mb={4}>
                          Meeting owner/Admin Login
                        </Title>
                        <Button
                          variant="contained"
                          onClick={() => handleSSORedirect()}
                          sx={{ fontWeight: "bold" }}
                        >
                          LILLY LOGIN
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>}
              </Grid>
            </Box>
            <Footer />
          </>) :
          <Box>
            <Typography variant="h3" textAlign="center" color="#1976d2">
              Unauthorised
            </Typography>
            <Typography variant="h4" textAlign="center">
              User
            </Typography>
          </Box>
      }
    </>
  );
}
export default Login;
